.bannerTabbar {
  height: 500px;
  overflow: hidden;
}
.bannerTabbar img {
  width: 100%;
}
.newsOmit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content {
  max-width: 1060px;
  margin: 5px auto 50px auto;
  font-family: auto;
}
.content .title {
  text-align: center;
  font-size: 30px;
  font-family: cursive;
  font-weight: bold;
  margin-bottom: 40px;
}
.content .newsImg {
  overflow: hidden;
  margin: 30px auto;
  border-radius: 5px;
  max-width: 1000px;
  max-height: 600px;
}
.content .newsImg .img {
  display: block;
  margin: 0 auto;
}
.content .newsImg .alt-desc {
  font-size: 13px;
  color: #8d8d8d;
  margin: 3px 0 0 0;
  text-align: center;
}
.content .paragraph {
  font-size: 15px;
  font-family: monospace;
  line-height: 24px;
  text-indent: 32px;
}
.content .cen-align {
  text-align: center;
}
.news-page .news-bar {
  height: auto;
  position: relative;
  margin-bottom: 2%;
}
.news-page .news-bar .bannerWrapper {
  width: 100%;
}
.news-page .news-bar .bannerWrapper img {
  width: 100%;
  height: 100%;
}
.news-page .fade-enter-active,
.news-page .fade-leave-active {
  transition: opacity 0.5s;
}
.news-page .fade-enter,
.news-page .fade-leave-to {
  opacity: 0;
}
.news-page .pagenav {
  margin-top: 2.71%;
  margin-bottom: 4.67%;
  text-align: center;
}
.news-page .pagenav .pagination li .active {
  background-color: #2b8dfa;
  border-color: #2b8dfa;
  color: #ffffff;
}
.news-page .pagenav .pagination li a {
  margin-right: 0.83rem;
}
.news-page .pagenav .pagination li a:hover {
  border-color: #2b8dfa;
  background-color: #ffffff;
}
.news-page .pagenav .pagination li a:focus {
  background-color: #2b8dfa;
  border-color: #2b8dfa;
  color: #ffffff;
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/ .newsListNav {
    height: 6rem;
  }
  .news-page .news-bar /deep/ .newsListNav .ListNav {
    margin-right: 15rem;
  }
}
